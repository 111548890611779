@mixin rainbowBackground {
    .rainbowOne {
        position: absolute;
        top: 0;
        left: 0;
        width: 50vw;
        height: auto;
        z-index: -1;
    }

    .rainbowTwo {
        position: absolute;
        right: 0;
        top: 0;
        width: 50vw;
        height: auto;
        z-index: -1;
    }

    .rainbowTwoReverse {
        position: absolute;
        left: 0;
        top: 0;
        width: 50vw;
        height: auto;
        z-index: -1;
        z-index: -1;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    .rainbowThree {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 50vw;
        height: auto;
        z-index: -1;
    }

    .rainbowThreeReverse {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50vw;
        height: auto;
        z-index: -1;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
}

@mixin rainbowBackgroundTablet {
    .rainbowOne {
        width: 80vw;
    }

    .rainbowTwo {
        width: 80vw;
    }

    .rainbowThree {
        width: 80vw;
    }
}

@mixin rainbowBackgroundMobile {
    .rainbowOne {
        width: 80vw;
    }

    .rainbowTwo {
        width: 80vw;
        transform: translateY(15rem);
    }

    .rainbowThree {
        width: 80vw;
    }
}
