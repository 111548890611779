@import "../_variables";
@import "../section/section.scss";
@import "../fonts/primaryfont.scss";
@import "../section/aboutProfile.scss";
@import "../backgrounds/titleContainer.scss";
@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";
@import "/node_modules/bootstrap/scss/carousel";
@import "../section/carousel.scss";
@import "../section/newsletter.scss";
@import "../section/upcomingShows.scss";

// about desktop
@mixin showsDesktop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .titleContainer {
        @include titleContainerDesktop;
    }

    .titleContainer.active {
        @include titleContainerDesktopActive;
    }

    .sections {
        position: relative;
    }

    section.one {
        @include upcomingShowsDesktop;
        @include yAxisAnimation;
        padding: 0 0 5rem 0;

        .contents {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            h1.secondaryfont {
                padding: 0;
            }
        }

        .information {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 0 1rem 0;

            h1 {
                padding: 0;
                margin: 0;
            }
        }

        h1 {
            text-align: left;
        }
    }

    section.one.active {
        @include yAxisAnimationVisible;
        padding: 0 0 5rem 0;
    }

    section.two {
        @include aboutProfileSection;
        @include yAxisAnimation;

        .contents {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            h1.secondaryfont {
                padding: 0;
            }
        }

        @include carouselDesktop;
    }

    section.two.active {
        @include yAxisAnimationVisible;
    }

    section.three {
        @include aboutProfileSection;
        @include yAxisAnimation;
        padding: 0;

        h1.secondaryfont {
            padding: 0;
        }

        .contents {
            width: 100%;
        }

        .newsletter {
            @include newsletterDesktop;
        }
    }

    section.three.active {
        @include yAxisAnimationVisible;
        padding: 0;
    }

    @include rainbowBackground;
}

@mixin showsTablet {
    @include rainbowBackgroundTablet;

    .titleContainer {
        @include tabletPrimaryTitle;
    }

    .titleContainer.active {
        @include titleContainerTabletActive;
    }

    section.one {
        @include upcomingShowsTablet;

        .newsletter {
            @include newsletterTablet;
        }
    }

    section.two {
        @include aboutProfileSectionTablet;
        @include carouselTablet;
    }

    section.three {
        @include aboutProfileSectionTablet;

        .newsletter {
            @include newsletterTablet;
        }
    }
}

@mixin showsMobile {
    .titleContainer {
        @include mobilePrimaryTitle;
    }

    .titleContainer.active {
        @include titleContainerMobileActive;
    }

    section.one {
        @include upcomingShowsMobile;

        .information {
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            h1 {
                padding: 0 0 1rem 0;
                margin: 0;
            }
        }
    }

    section.two {
        @include aboutProfileSectionMobile;
        @include carouselMobile;
    }

    section.three {
        @include aboutProfileSectionMobile;

        .newsletter {
            @include newsletterMobile;
        }
    }
}
