@import "../_variables";
@import "../section/section.scss";
@import "../fonts/primaryfont.scss";
@import "../section/aboutProfile.scss";
@import "../backgrounds/titleContainer.scss";
@import "../section/accordion.scss";
@import "../section/accordion.scss";

// about desktop
@mixin forParentsDesktop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .titleContainer {
        @include titleContainerDesktop;
    }

    .titleContainer.active {
        @include titleContainerDesktopActive;
    }

    @include accordionDesktop;

    @include rainbowBackground;
}

@mixin forParentsTablet {
    .titleContainer {
        @include tabletPrimaryTitle;
    }

    .titleContainer.active {
        @include titleContainerTabletActive;
    }

    @include accordionTablet;
}

@mixin forParentsMobile {
    .titleContainer {
        @include mobilePrimaryTitle;
    }

    .titleContainer.active {
        @include titleContainerMobileActive;
    }

    @include accordionMobile;
}
