//desktop section
@mixin desktopSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    position: relative;
    width: 100vw;

    .title {
        width: $desktopWidth;
        top: 0;
        padding: 0;
        margin: 0;
    }
}

@mixin desktopSectionActive {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    position: relative;
    width: 100vw;

    .title {
        padding: 6rem 0 0 0;
        width: $desktopWidth;
        top: 0;
    }
}

//tablet section
@mixin tabletSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    position: relative;
    width: 100vw;

    .title {
        width: $tabletWidth;
        top: 0;
        padding: 0;
        margin: 0;
    }
}

// mobile section
@mixin mobileSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    position: relative;
    width: $mobileWidth;

    .title {
        width: $mobileWidth !important;
        top: 0;
        padding: 0;
        margin: 0;
    }
}
