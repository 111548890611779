@import "../_variables";

.pfont {
    font-size: $paragraphDesktopSize;
    text-align: center;
    margin: 1rem 0;
    color: $black;
    font-weight: $fontLight;
    line-height: 2rem;
    margin: 0;
}

.pfont.mobile {
    font-size: $paragraphDesktopSize;
    text-align: center;
    margin: 1rem 0;
    color: $black;
    font-weight: $fontLight;
    line-height: 2rem;
    margin: 0;
}
