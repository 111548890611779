@import "../_variables";

// desktop navbutton
@mixin navButton {
    background-color: $white;
    @include boxShadowOne;
    cursor: pointer;
    text-transform: capitalize;
    padding: 0;
    margin: 0;
    width: 6.35rem;
    height: 3.5rem;
    border: double 4px white;
    border-radius: $borderRadiusOne;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    transition: 1s all;
    text-decoration: none;
}

// desktop active navbutton
@mixin navButtonActive {
    background-color: $white;
    @include boxShadowOne;
    cursor: pointer;
    text-transform: capitalize;
    padding: 0;
    margin: 0;
    width: 7rem;
    height: 3.5rem;
    border: double 4px transparent;
    border-radius: $borderRadiusOne;
    background-image: linear-gradient(white, white),
        radial-gradient(circle at top left, $green, $pink);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    transition: $transitionOne;
    text-decoration: none;
}

// mobile navbutton
@mixin navBarMobile {
    @include navButton;
    width: 50vw;
}

// mobile active navbutton
@mixin navBarMobileActive {
    @include navButtonActive;
    width: 50vw;
}
