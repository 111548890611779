@import "../_variables";

@mixin footerDesktop {
    width: 100vw;
    @include boxShadowOne;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 100;

    .footerContents {
        width: $desktopWidth;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .socialMediaContainer {
            padding: 8rem 0 1.5rem 0;

            img {
                padding: 0 0.75rem;
                cursor: pointer;
                transition: $transitionTwo;
            }

            img.facebook:hover {
                transform: scale(1.15);
                transition: $transitionTwo;
            }

            img.instagram:hover {
                transform: scale(1.15);
                transition: $transitionTwo;
            }

            img.tiktok:hover {
                transform: scale(1.15);
                transition: $transitionTwo;
            }
        }

        .titles {
            padding: 0 0 8rem 0;
            .pfont {
                padding: 0.5rem 0;
            }

            .pfont.copyright {
                font-weight: $fontHeavy;
            }
        }
    }
}

@mixin footerTablet {
    .footerContents {
        width: $tabletWidth;
    }
}

@mixin footerMobile {
    .footerContents {
        width: $mobileWidth;
    }
}
