@import "../_variables";

//navbar desktop
@mixin navbarDesktop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    margin-left: calc(-800px / 2);
    z-index: 100;

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        width: 100%;
        padding: 3rem 0;
        margin: 0;

        li {
            padding: 0 1rem;

            a {
                text-decoration: none;
                button {
                    @include navButton;
                }

                button.active {
                    @include navButtonActive;
                }

                button:hover {
                    @include navButtonActive;
                }
            }
        }
    }

    .burgerMenuContainer {
        display: none;
    }
}

//navbar tablet
@mixin navbarTablet {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    margin-left: calc(-720px / 2);

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        width: 100%;
        padding: 3rem 0;
        margin: 0;

        li {
            padding: 0 0.6rem;

            a {
                text-decoration: none;
                button {
                    @include navButton;
                }

                button.active {
                    @include navButtonActive;
                }

                button:hover {
                    @include navButtonActive;
                }
            }
        }
    }

    .burgerMenuContainer {
        display: none;
    }
}

//navbar mobile
@mixin navbarMobile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 5rem;
    background-color: $white;
    @include boxShadowOne;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100vw;
    z-index: 3;
    transition: $transitionFour;
    animation: navAnimation 1s normal forwards ease-in-out;
    position: fixed;
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
    background-color: rgba(255, 255, 255, 0.8);

    ul {
        visibility: hidden;
        display: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: $transitionFour;
        opacity: 0%;

        li {
            margin: 2rem 0;

            a {
                button {
                    width: 50vw !important;
                }
            }
        }
    }

    .burgerMenuContainer {
        display: initial;
        position: absolute;
        right: calc($mobileSidePadding * 1);
        content: "";
        width: 2rem;
        height: 2rem;
        top: calc(5rem / 2 - 1rem);

        .burgerMenuTrigger {
            position: absolute;
            content: "";
            width: 2rem;
            height: 2rem;
            z-index: 3;
            opacity: 0.1;
            cursor: pointer;
        }

        .burgerMenu {
            cursor: pointer;
            position: absolute;
            content: "";
            background-color: $pink;
            width: 1.25rem;
            height: 0.3rem;
            z-index: 2;
            top: calc(2rem / 2 - 0.3rem / 2);
            right: 0;
            border-radius: $borderRadiusOne;
            display: flex;
            justify-content: flex-end;
            transition: $transitionTwo;
        }

        .burgerMenu::after {
            position: absolute;
            content: "";
            width: 1.75rem;
            height: 0.3rem;
            background-color: $pink;
            border-radius: $borderRadiusOne;
            top: calc(2rem / 2 - 0.6rem / 2);
            transition: $transitionFour;
        }

        .burgerMenu::before {
            position: absolute;
            content: "";
            width: 1.75rem;
            height: 0.3rem;
            background-color: $pink;
            border-radius: $borderRadiusOne;
            top: calc(-2rem / 2 + 0.6rem / 2);
            transition: $transitionFour;
        }
    }
}

//navbar mobile active
@mixin navbarMobileActive {
    height: 100vh;
    ul {
        opacity: 100%;
        visibility: visible;
        display: flex;
        transition: $transitionFour;
    }

    .burgerMenuContainer {
        .burgerMenu {
            width: 0px;
            transition: $transitionFour;
        }

        .burgerMenu::after {
            transition: $transitionFour;
            transform: rotate(45deg);
            top: 0;
        }

        .burgerMenu::before {
            transition: $transitionFour;
            transform: rotate(-45deg);
            top: 0;
        }
    }
}

@keyframes navAnimation {
    0% {
        transform: translateY(-10rem);
    }
    100% {
        transform: translateY(0rem);
    }
}
