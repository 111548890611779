@import "../_variables";

.thirdfont {
    font-size: $thirdDesktopSize;
    padding: 0 1rem;
    margin: 1rem 0;
    color: $black;
}

.thirdfont.mobile {
    font-size: $thirdMobileSize;
    padding: 0 1rem;
    margin: 1rem 0;
    color: $black;
}
