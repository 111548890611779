@import "../_variables";

@mixin aboutProfileSection {
    width: $desktopWidth;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 6rem 0;

    img.lisa {
        height: auto;
        width: 50%;
    }

    .contents {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
}

@mixin aboutProfileSectionTablet {
    width: $tabletWidth;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 6rem 0;

    img.lisa {
        height: auto;
        width: 50%;
    }

    .contents {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
}

@mixin aboutProfileSectionMobile {
    width: $mobileWidth;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 0 6rem 0;

    img.lisa {
        height: auto;
        width: 50%;
    }

    .contents {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
}
