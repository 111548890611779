@import "../_variables";
@import "../section/section.scss";
@import "../fonts/primaryfont.scss";
@import "../section/aboutProfile.scss";
@import "../backgrounds/titleContainer.scss";

// about desktop
@mixin aboutDesktop {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .titleContainer {
    @include titleContainerDesktop;
  }

  .titleContainer.active {
    @include titleContainerDesktopActive;
  }

  .sections {
    position: relative;
  }

  section.one {
    @include aboutProfileSection;
    @include yAxisAnimation;

    h1.secondaryfont {
      color: $green;
      padding: 0;
    }

    h1.pfont {
      text-align: start;
    }
  }

  section.one.active {
    @include yAxisAnimationVisible;
  }

  section.two {
    @include aboutProfileSection;
    @include yAxisAnimation;

    h1.secondaryfont {
      color: $blue;
      padding: 0;
    }

    h1.pfont {
      text-align: start;
    }
  }

  section.two.active {
    @include yAxisAnimationVisible;
  }

  section.three {
    @include aboutProfileSection;
    @include yAxisAnimation;

    h1.secondaryfont {
      color: $orange;
      padding: 0;
    }

    h1.pfont {
      text-align: start;
    }
  }

  section.three.active {
    @include yAxisAnimationVisible;
  }

  @include rainbowBackground;
}

@mixin aboutTablet {
  @include rainbowBackgroundTablet;

  .titleContainer {
    @include tabletPrimaryTitle;
  }

  .titleContainer.active {
    @include titleContainerTabletActive;
  }

  section.one {
    @include aboutProfileSectionTablet;
  }

  section.two {
    @include aboutProfileSectionTablet;
  }

  section.three {
    @include aboutProfileSectionTablet;
  }
}

@mixin aboutMobile {
  .titleContainer {
    @include mobilePrimaryTitle;
  }

  .titleContainer.active {
    @include titleContainerMobileActive;
  }

  section.one {
    @include aboutProfileSectionMobile;
  }

  section.two {
    @include aboutProfileSectionMobile;
  }

  section.three {
    @include aboutProfileSectionMobile;
  }
}
