@import "./_variables";
@import "../styles/navbar/_navButton.scss";
@import "./navbar/_navBar";
@import "./button/button.scss";
@import "./pages/home.scss";
@import "./pages/about.scss";
@import "./pages/forParents.scss";
@import "./footer/footer.scss";
@import "./fonts/primaryfont.scss";
@import "./fonts/secondaryfont.scss";
@import "./fonts//thirdfont.scss";
@import "./fonts/pfont.scss";
@import "./section/section.scss";
@import "./pages/shows.scss";
@import "./pages/contact.scss";
@import "./section/upcomingShows.scss";

html {
    padding: 0;
    margin: 0;
    font-family: $fontFamily;
    overflow-x: hidden;
}

body {
    padding: 0;
    margin: 0;
}

.App {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 1000;
    flex-direction: column;

    img {
        width: auto;
        height: 10rem;
        padding: 0 0 3rem 0;
    }
}

.navbar {
    @include navbarDesktop;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home {
    @include homeDesktop;
}

.about {
    @include aboutDesktop;
}

.shows {
    @include showsDesktop;
}

.forParents {
    @include forParentsDesktop;
}

.contact {
    @include contactDesktop;
}

footer {
    @include footerDesktop;
}

@media (max-width: 900px) {
    .navbar {
        @include navbarTablet;
    }

    .home {
        @include homeTablet;
    }

    .about {
        @include aboutTablet;
    }

    .shows {
        @include showsTablet;
    }

    .forParents {
        @include forParentsTablet;
    }

    .contact {
        @include contactTablet;
    }

    footer {
        @include footerTablet;
    }
}

@media (max-width: 700px) {
    .navbar {
        @include navbarMobile;
    }

    .navbar.active {
        @include navbarMobileActive;
    }

    .home {
        @include homeMobile;
    }

    .about {
        @include aboutMobile;
    }

    .shows {
        @include showsMobile;
    }

    .forParents {
        @include forParentsMobile;
    }

    .contact {
        @include contactMobile;
    }

    footer {
        @include footerMobile;
    }
}
