// GLOBAL VARIABLES
// font weights
$fontFamily: "Lato", sans-serif;
$fontHeavy: 700;
$fontMedium: 400;
$fontLight: 300;

// colors
$white: #ffffff;
$lightGrey: #d9d9d9;
$black: #4a4a4a;
$grey: #696969;
$green: #6fcf97;
$pink: #ff7fc7;
$blue: #7fc8ff;
$orange: #f7a32d;

// border-radius
$borderRadiusOne: 0.75rem;
$borderRadiusTwo: 2rem;

// transitions
$transitionOne: 0.25s all;
$transitionTwo: 0.125s all;
$transitionThree: 1s all;
$transitionFour: 0.35s all;

//box-shadow
@mixin boxShadowOne {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}

// inner-shaodw
@mixin innerShadow {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1) inset;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1) inset;
}

// y axis animation
@mixin yAxisAnimation {
  transform: translateY(10rem);
  transition: $transitionThree;
  opacity: 0%;
}

@mixin yAxisAnimationVisible {
  transform: translateY(0rem);
  transition: $transitionThree;
  opacity: 100%;
}

// nav animation
@mixin navAnimation {
  transform: translateY(-15rem);
  transition: $transitionFour;
  opacity: 0%;
}

@mixin navAnimationVisible {
  transform: translateY(0rem);
  transition: $transitionFour;
  opacity: 100%;
}

//width
$desktopWidth: 50rem;
$tabletWidth: calc(100vw - 8rem);
$mobileWidth: calc(100vw - 4rem);

//padding
$mobileSidePadding: 2rem;
$desktopSidePadding: 20rem;
$desktopPadding: 0 $desktopSidePadding;
$mobileSidePadding: 2rem;

// font calulcation
$fontCalculation: 1.35;

//font sizes
$buttonDesktopSize: calc(28px / $fontCalculation);
$primaryDesktopSize: calc(96px / $fontCalculation);
$secondaryDesktopSize: calc(64px / $fontCalculation);
$thirdDesktopSize: calc(40px / $fontCalculation);
$paragraphDesktopSize: calc(24px / $fontCalculation);

$primaryMobileSize: calc($primaryDesktopSize / 1.15 / $fontCalculation);
$secondaryMobileSize: calc($secondaryDesktopSize / 1.15 / $fontCalculation);
$thirdMobileSize: calc($thirdDesktopSize / 1.15 / $fontCalculation);
$paragraphMobileSize: calc($paragraphDesktopSize / 1.15 / $fontCalculation);
