@import "../_variables";

// global font
.primaryfont {
    font-size: $primaryDesktopSize;
    text-align: center;
    padding: 0 1rem;
    margin: 1rem 0;
    color: $black;
}

.mobilefont {
    font-size: $primaryMobileSize;
    text-align: center;
    padding: 0 1rem;
    margin: 1rem 0;
    color: $black;
}

// header font
@mixin desktopPrimaryTitle {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15rem;
    padding: 7.5rem 0 0 0;

    .title {
        width: $desktopWidth;
        background-size: cover;
        border-radius: $borderRadiusOne;
        padding: 1.25rem 0;
        @include innerShadow;
    }
}

@mixin tabletPrimaryTitle {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15rem;
    padding: 7.5rem 0 0 0;

    .title {
        width: $tabletWidth;
        background-size: cover;
        border-radius: $borderRadiusOne;
        padding: 1.25rem 0;
        @include innerShadow;
    }
}

@mixin mobilePrimaryTitle {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15rem;
    padding: 7.5rem 0 0 0;

    .title {
        width: $mobileWidth;
        background-size: cover;
        border-radius: $borderRadiusOne;
        padding: 1.25rem 0;
        @include innerShadow;
    }
}
