@import "../_variables";

.secondaryfont {
    font-size: $secondaryDesktopSize;
    text-align: center;
    padding: 0 1rem;
    margin: 1rem 0;
    color: $black;
}

.secondaryfont.mobile {
    font-size: $secondaryMobileSize;
    text-align: center;
    padding: 0 1rem;
    margin: 1rem 0;
    color: $black;
}
