@import "../_variables";
@import "../section/section.scss";
@import "../fonts/primaryfont.scss";
@import "../section/aboutProfile.scss";
@import "../backgrounds/titleContainer.scss";
@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";
@import "/node_modules/bootstrap/scss/carousel";
@import "../section/carousel.scss";
@import "../section/newsletter.scss";

// about desktop
@mixin contactDesktop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .titleContainer {
        @include titleContainerDesktop;
    }

    .titleContainer.active {
        @include titleContainerDesktopActive;
    }

    .sections {
        position: relative;
    }

    section.one {
        @include aboutProfileSection;
        @include yAxisAnimation;

        .contents {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            form {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;

                h1.pfont {
                    text-align: left;
                    font-weight: $fontMedium;
                }

                .nameContainer {
                    width: 100%;
                    padding: 0 0 2rem 0;

                    input {
                        width: calc(100% - 1rem * 2);
                        padding: 1rem;
                        border-radius: $borderRadiusOne;
                        border: 1px solid $grey;
                        font-size: 1rem;
                        background-color: transparent;
                    }
                }

                .organisationContainer {
                    width: 100%;
                    padding: 0 0 2rem 0;

                    input {
                        width: calc(100% - 1rem * 2);
                        padding: 1rem;
                        border-radius: $borderRadiusOne;
                        border: 1px solid $grey;
                        font-size: 1rem;
                        background-color: transparent;
                    }
                }

                .enquiryContainer {
                    width: 100%;
                    padding: 0 0 2rem 0;

                    textarea {
                        width: calc(100% - 1rem * 2);
                        max-width: calc(100% - 1rem * 2);
                        min-width: calc(100% - 1rem * 2);
                        max-height: 10rem;
                        height: 10rem;
                        padding: 1rem;
                        border-radius: $borderRadiusOne;
                        border: 1px solid $grey;
                        font-size: 1rem;
                        background-color: transparent;
                        font-family: $fontFamily;
                    }
                }

                a {
                    width: 100% !important;

                    button {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    section.one.active {
        @include yAxisAnimationVisible;
    }

    @include rainbowBackground;
}

@mixin contactTablet {
    @include rainbowBackgroundTablet;

    .titleContainer {
        @include tabletPrimaryTitle;
    }

    .titleContainer.active {
        @include titleContainerTabletActive;
    }

    section.one {
        @include aboutProfileSectionTablet;
    }
}

@mixin contactMobile {
    .titleContainer {
        @include mobilePrimaryTitle;
    }

    .titleContainer.active {
        @include titleContainerMobileActive;
    }

    section.one {
        @include aboutProfileSectionMobile;
    }
}
