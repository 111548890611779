@import "../_variables";
@import "../section/section.scss";
@import "../fonts/primaryfont.scss";
@import "../section/aboutProfile.scss";
@import "../backgrounds/titleContainer.scss";

@mixin accordionDesktop {
    .accordionContainer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: $desktopWidth;
        padding: 2rem 0 4rem 0;
        @include yAxisAnimation;

        .accordionItem {
            border-bottom: 1.5px solid $lightGrey;
            margin: 0 0 3rem 0;
            width: $desktopWidth;
        }

        .accordionQuestion {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: $desktopWidth;

            span {
                padding: 0 0 0 0.75rem;
                img {
                    height: auto;
                    width: 75%;
                    transform: rotate(180deg);
                    transition: $transitionFour;
                }

                img.active {
                    transform: rotate(0deg);
                    transition: $transitionFour;
                }
            }

            h1.thirdfont {
                padding: 0;
                margin: 1rem 0;
                line-height: 2.5rem;
            }
        }

        .dropdown {
            width: $desktopWidth;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0 0 1rem 0;

            .pfont {
                text-align: left;
            }
        }
    }

    .accordionContainer.active {
        @include yAxisAnimationVisible;
    }
}

@mixin accordionTablet {
    .accordionContainer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: $tabletWidth;
        padding: 2rem 0 4rem 0;
        @include yAxisAnimation;

        .accordionItem {
            border-bottom: 1.5px solid $lightGrey;
            margin: 0 0 3rem 0;
            width: $tabletWidth;
        }

        .accordionQuestion {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: $tabletWidth;

            span {
                img {
                    height: auto;
                    width: 75%;
                    transform: rotate(180deg);
                    transition: $transitionFour;
                }

                img.active {
                    transform: rotate(0deg);
                    transition: $transitionFour;
                }
            }

            h1.thirdfont {
                padding: 0;
                margin: 1rem 0;
            }
        }

        .dropdown {
            width: $tabletWidth;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0 0 1rem 0;

            .pfont {
                text-align: left;
            }
        }
    }

    .accordionContainer.active {
        @include yAxisAnimationVisible;
    }
}

@mixin accordionMobile {
    .accordionContainer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: $mobileWidth;
        padding: 2rem 0 4rem 0;
        @include yAxisAnimation;

        .accordionItem {
            border-bottom: 1.5px solid $lightGrey;
            margin: 0 0 3rem 0;
            width: $mobileWidth;
        }

        .accordionQuestion {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: $mobileWidth;

            span {
                padding: 0 0 0 0.75rem;
                img {
                    height: auto;
                    width: 75%;
                    transform: rotate(180deg);
                    transition: $transitionFour;
                }

                img.active {
                    transform: rotate(0deg);
                    transition: $transitionFour;
                }
            }

            h1.thirdfont {
                padding: 0;
                margin: 1rem 0;
                line-height: 2rem;
            }
        }

        .dropdown {
            width: $mobileWidth;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0 0 1rem 0;

            .pfont {
                text-align: left;
            }
        }
    }

    .accordionContainer.active {
        @include yAxisAnimationVisible;
    }
}
