@import "../_variables";

@mixin newsletterDesktop {
    width: $desktopWidth;

    .details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;

        h1.pfont {
            font-weight: $fontHeavy;
            font-style: italic;
            font-size: 90%;
            border: none;
            padding: 0;
        }
    }

    h1.pfont {
        text-align: left;
        border-bottom: solid 1px $lightGrey;
        padding: 0 0 2rem 0;
    }

    img {
        width: $desktopWidth;
        height: auto;
    }

    .contents {
        padding: 0 0 5rem 0;
    }
}

@mixin newsletterTablet {
    width: $tabletWidth;

    img {
        width: $tabletWidth;
        height: auto;
    }
}

@mixin newsletterMobile {
    width: $mobileWidth;

    img {
        width: $mobileWidth;
        height: auto;
    }
}
