@import "../_variables";

// submit button
button.submit {
    background-color: $black !important;
    border: none !important;
    width: 100% !important;
    height: auto !important;
    border-radius: $borderRadiusOne !important;
    cursor: pointer !important;
    transition: $transitionTwo !important;
    color: white !important;
    padding: 1.5rem 0;
}

// desktop button black
button.black {
    background-color: $black !important;
    border: none !important;
    width: auto !important;
    height: auto !important;
    border-radius: $borderRadiusOne !important;
    cursor: pointer !important;
    transition: $transitionTwo !important;

    .items {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        p {
            color: $white !important;
            text-decoration: none !important;
            font-size: $buttonDesktopSize !important;
            text-transform: capitalize !important;
            padding: 0 0 0 2rem !important;
        }

        img {
            transition: $transitionTwo !important;
            width: 15px !important;
            height: 15px !important;
            padding: 0 1rem !important;
        }
    }
}

button.black:hover {
    background-color: $grey !important;
    transition: $transitionTwo !important;
    transform: scale(1.025) !important;

    .items {
        img {
            transition: $transitionTwo !important;
            transform: translateX(0.25rem) !important;
        }
    }
}

// desktop button white
button.white {
    background-color: transparent !important;
    border: none !important;
    width: auto !important;
    height: auto !important;
    border-radius: $borderRadiusOne !important;
    cursor: pointer !important;
    transition: $transitionTwo !important;

    .items {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        p {
            color: $black !important;
            text-decoration: none !important;
            font-size: $buttonDesktopSize !important;
            text-transform: capitalize !important;
            padding: 0 0 0 2rem !important;
        }

        img {
            width: 15px !important;
            height: 15px !important;
            padding: 0 1rem !important;
            transition: $transitionTwo !important;
        }
    }
}

button.white:hover {
    transition: $transitionTwo !important;
    transform: scale(1.025) !important;

    img {
        transition: $transitionTwo !important;
        transform: translateX(0.25rem) !important;
    }
}

// desktop button white border
button.whiteborder {
    background-color: transparent !important;
    border: none !important;
    width: auto !important;
    height: auto !important;
    border-radius: $borderRadiusOne !important;
    cursor: pointer !important;
    transition: $transitionTwo !important;
    border: 3px solid $black !important;

    .items {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        p {
            color: $black !important;
            text-decoration: none !important;
            font-size: $buttonDesktopSize !important;
            text-transform: capitalize !important;
            padding: 0 0 0 2rem !important;
        }

        img {
            width: 15px !important;
            height: 15px !important;
            padding: 0 1rem !important;
            transition: $transitionTwo !important;
        }
    }
}

button.whiteborder:hover {
    transition: $transitionTwo !important;
    border: 3px solid $black !important;
    transform: scale(1.025) !important;

    img {
        transition: $transitionTwo !important;
        transform: translateX(0.25rem) !important;
    }
}

// mobile button black
button.black.mobile {
    background-color: $black !important;
    border: none !important;
    width: auto !important;
    height: auto !important;
    border-radius: $borderRadiusOne !important;
    cursor: pointer !important;
    transition: $transitionTwo !important;
    width: calc($mobileWidth - 2rem) !important;

    .items {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        p {
            color: $white !important;
            text-decoration: none !important;
            font-size: $buttonDesktopSize !important;
            text-transform: capitalize !important;
            padding: 0 0 0 2rem !important;
        }

        img {
            transition: $transitionTwo !important;
            width: 15px !important;
            height: 15px !important;
            padding: 0 1rem !important;
        }
    }
}

button.white.mobile {
    background-color: transparent;
    border: none;
    width: auto;
    height: auto;
    border-radius: $borderRadiusOne;
    cursor: pointer;
    transition: $transitionTwo;

    .items {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            color: $black;
            text-decoration: none;
            font-size: $buttonDesktopSize;
            text-transform: capitalize;
            padding: 0 0 0 2rem;
        }

        img {
            width: 15px;
            height: 15px;
            padding: 0 1rem;
            transition: $transitionTwo;
        }
    }
}

button.whiteborder.mobile {
    background-color: transparent;
    border: none;
    height: auto;
    border-radius: $borderRadiusOne;
    cursor: pointer;
    transition: $transitionTwo;
    border: 3px solid $black;
    width: calc($mobileWidth);

    .items {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            color: $black;
            text-decoration: none;
            font-size: $buttonDesktopSize;
            text-transform: capitalize;
            padding: 0 0 0 2rem;
        }

        img {
            width: 15px;
            height: 15px;
            padding: 0 1rem;
            transition: $transitionTwo;
        }
    }
}
