@import "../_variables";

@mixin upcomingShowsDesktop {
    width: $desktopWidth;

    img {
        width: $desktopWidth;
        height: auto;
    }
}

@mixin upcomingShowsTablet {
    width: $tabletWidth;

    img {
        width: $tabletWidth;
        height: auto;
    }
}

@mixin upcomingShowsMobile {
    width: $mobileWidth;

    img {
        width: $mobileWidth;
        height: auto;
    }
}
