@import "../_variables";

@mixin carouselDesktop {
    .carousel.slide {
        width: $desktopWidth;
        height: calc($desktopWidth / 1.5);

        .carousel-inner {
            width: $desktopWidth;
            height: calc($desktopWidth / 1.5);

            .carousel-item {
                width: $desktopWidth;
                height: calc($desktopWidth / 1.5);
            }

            .active.carousel-item {
                width: $desktopWidth;
                height: calc($desktopWidth / 1.5);
                img {
                    width: $desktopWidth;
                    height: calc($desktopWidth / 1.5);
                }
            }

            .carousel-item-next.carousel-item-start.carousel-item {
                width: $desktopWidth;
                height: calc($desktopWidth / 1.5);
                img {
                    width: $desktopWidth;
                    height: calc($desktopWidth / 1.5);
                }
            }

            .carousel-item-prev.carousel-item-end.carousel-item {
                width: $desktopWidth;
                height: calc($desktopWidth / 1.5);
                img {
                    width: $desktopWidth;
                    height: calc($desktopWidth / 1.5);
                }
            }
        }
    }

    span.visually-hidden {
        display: none;
    }
}

@mixin carouselTablet {
    .carousel.slide {
        width: $tabletWidth;
        height: calc($tabletWidth / 1.5);

        .carousel-inner {
            width: $tabletWidth;
            height: calc($tabletWidth / 1.5);

            .carousel-item {
                width: $tabletWidth;
                height: calc($tabletWidth / 1.5);
            }

            .active.carousel-item {
                width: $tabletWidth;
                height: calc($tabletWidth / 1.5);
                img {
                    width: $tabletWidth;
                    height: calc($tabletWidth / 1.5);
                }
            }

            .carousel-item-next.carousel-item-start.carousel-item {
                width: $tabletWidth;
                height: calc($tabletWidth / 1.5);
                img {
                    width: $tabletWidth;
                    height: calc($tabletWidth / 1.5);
                }
            }

            .carousel-item-prev.carousel-item-end.carousel-item {
                width: $tabletWidth;
                height: calc($tabletWidth / 1.5);
                img {
                    width: $tabletWidth;
                    height: calc($tabletWidth / 1.5);
                }
            }
        }
    }
}

@mixin carouselMobile {
    .carousel.slide {
        width: $mobileWidth;
        height: calc($mobileWidth / 1.5);

        .carousel-inner {
            width: $mobileWidth;
            height: calc($mobileWidth / 1.5);

            .carousel-item {
                width: $mobileWidth;
                height: calc($mobileWidth / 1.5);
            }

            .active.carousel-item {
                width: $mobileWidth;
                height: calc($mobileWidth / 1.5);
                img {
                    width: $mobileWidth;
                    height: calc($mobileWidth / 1.5);
                }
            }

            .carousel-item-next.carousel-item-start.carousel-item {
                width: $mobileWidth;
                height: calc($mobileWidth / 1.5);
                img {
                    width: $mobileWidth;
                    height: calc($mobileWidth / 1.5);
                }
            }

            .carousel-item-prev.carousel-item-end.carousel-item {
                width: $mobileWidth;
                height: calc($mobileWidth / 1.5);
                img {
                    width: $mobileWidth;
                    height: calc($mobileWidth / 1.5);
                }
            }
        }
    }
}
