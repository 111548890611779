@import "../_variables";
@import "../section/section.scss";
@import "../fonts/primaryfont.scss";
@import "../backgrounds/rainbowBackground.scss";

// home desktop
@mixin homeDesktop {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include boxShadowOne;
    height: auto;
    width: 100vw;
    background-color: white;

    .headerContents {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: calc(100% - 152px);
      width: $desktopWidth;
      padding: 6rem 0 4rem 0;
      @include yAxisAnimation;
      z-index: 0;

      .logoContainer {
        z-index: 3;
        transform: translateY(3rem);
        img {
          position: relative;
          width: auto;
          height: 13rem;
          z-index: 1;
        }
      }

      .backdrop {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        height: 20rem;
        padding: 1rem 0 2rem 0;
        background-repeat: no-repeat;
        border-radius: $borderRadiusTwo;
        @include innerShadow;
        background-size: cover;

        canvas {
          position: absolute;
          top: 0;
          z-index: -1;
          border-radius: $borderRadiusOne;
          background-size: cover;
        }
      }
    }

    .headerContents.active {
      @include yAxisAnimationVisible;
    }
  }

  .sections {
    position: relative;

    section.one {
      @include desktopSection;
      height: 50.7rem;
      margin: 0 0 6rem 0;
      @include yAxisAnimation;

      .titleContainer {
        position: absolute;
        top: 0;
        padding: 6rem 0 0 0;
      }

      canvas {
        position: absolute;
        top: 0;
        z-index: 2;
        transform: translateY(-5rem);
      }

      .buttonContainer {
        button {
          position: relative;
          z-index: 3;
          margin: 30rem 0 0 0;
        }
      }
    }

    section.one.active {
      @include yAxisAnimationVisible;
    }

    section.two {
      @include desktopSection;
      @include yAxisAnimation;

      .imagesContainer {
        width: $desktopWidth;
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        margin: 2rem 0 0 0;

        img {
          width: 24rem;
          height: auto;
        }
      }

      .buttonContainer {
        padding: 3rem 0 6rem 0;
      }
    }

    section.two.active {
      @include yAxisAnimationVisible;
    }

    section.three {
      @include desktopSection;
      @include yAxisAnimation;

      .imagesContainer {
        width: $desktopWidth;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem 0 0 0;

        img {
          width: 35rem;
          height: auto;
        }
      }

      .buttonContainer {
        padding: 3rem 0 6rem 0;
      }
    }

    section.three.active {
      @include yAxisAnimationVisible;
    }
  }

  @include rainbowBackground;
}

// home tablet
@mixin homeTablet {
  header {
    .headerContents {
      width: $tabletWidth;

      .logoContainer {
        transform: translateY(3rem);
      }

      .backdrop {
        height: auto;
      }
    }
  }

  .sections {
    position: relative;

    section.one {
      @include tabletSection;
      padding: 0;
      height: 50.7rem;
      margin: 0 0 6rem 0;

      .titleContainer {
        position: absolute;
        top: 0;
        padding: 6rem 0 0 0;
      }

      canvas {
        position: absolute;
        top: -4rem;
        z-index: 2;
        transform: scale(90%);
      }
    }

    section.two {
      @include tabletSection;

      .imagesContainer {
        width: $tabletWidth;
        display: grid;
        grid-row: 1fr;
        grid-template-columns: 1fr 1fr;
        margin: 2rem 0 0 0;

        img {
          width: 20rem;
          height: auto;
        }
      }

      .buttonContainer {
        padding: 3rem 0 6rem 0;
      }
    }

    @include rainbowBackgroundTablet;
  }
}

// homeMobile
@mixin homeMobile {
  header {
    .headerContents {
      width: $mobileWidth;
      z-index: 1;

      .logoContainer {
        position: relative;

        img {
          height: 10rem;
        }
      }

      .backdrop {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        height: auto;
        margin: 1rem 0 0 0;
        width: $mobileWidth;

        .buttonContainer {
          margin: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
  }

  section.one {
    @include mobileSection;
    width: $mobileWidth;
    height: auto !important;

    .titleContainer {
      width: $mobileWidth;
      position: relative !important;

      .title {
        width: $mobileWidth;
      }
    }

    canvas {
      transform: scale(100%);
      top: 0rem;
    }

    .mobileImage {
      img {
        width: 110vw;
        height: auto;
      }
    }

    .buttonContainer {
      button {
        z-index: 3;
        margin: 2rem 0 5rem 0 !important;
      }
    }
  }

  section.two {
    @include mobileSection;
    width: $mobileWidth;

    .imagesContainer {
      width: $mobileWidth !important;
      grid-template-columns: 1fr !important;
      grid-gap: 1rem;
      grid-template-rows: 1fr 1fr !important;

      img {
        width: $mobileWidth !important;
      }
    }
  }

  section.three {
    @include mobileSection;
    width: $mobileWidth;

    .imagesContainer {
      width: $mobileWidth !important;
      grid-template-columns: 1fr !important;
      grid-gap: 1rem;
      grid-template-rows: 1fr 1fr !important;

      img {
        width: $mobileWidth !important;
      }
    }
  }

  @include rainbowBackgroundMobile;
}
